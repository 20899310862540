import React from 'react'

import ToolCard from '@c/tool-card'
import makeSlug from '@u/make-slug'
import ProjectImage from '@/components/project-image'

const List = ({ locale, slug, tools, tryToolLinkText }) => {
  return (
    <div className="container ta-center mt-60">
      <div className="row">
        {tools.map((tool) => (
          <div key={tool.id} className="col-12 col-lg-4 mb-32">
            <ToolCard>
              <ToolCard.Logo>
                <ProjectImage
                  className="mono"
                  image={tool.card[0]?.logoMono}
                  loading="eager"
                  width="60"
                  height="60"
                  style={{ width: 60, height: 60 }}
                />
                <ProjectImage
                  className="normal"
                  image={tool.card[0]?.logo}
                  loading="eager"
                  width="60"
                  height="60"
                  style={{ width: 60, height: 60 }}
                />
              </ToolCard.Logo>
              <ToolCard.Title>{tool.card[0]?.title}</ToolCard.Title>
              <ToolCard.Summary>
                <p>{tool.card[0]?.description}</p>
              </ToolCard.Summary>
              <ToolCard.Link url={makeSlug.generic(locale, slug, tool.slug)}>
                {tryToolLinkText}
              </ToolCard.Link>
            </ToolCard>
          </div>
        ))}
      </div>
    </div>
  )
}

export default List
