import React from 'react'

import PageHeader from '@c/page-header'

const Hero = ({ title, description, buttonText, buttonUrl }) => {
  return (
    <div className="tools-hero pb-lg-200 pb-xl-260 mt-lg-n60 pt-lg-60">
      <div className="container ta-center">
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-3">
            {/*  mobile-photo */}
            <div className="d-lg-none">
              <img src="/assets/tools/hero-mobile.png" alt="a" />
            </div>

            {/* title */}
            <div className="mt-40 mt-lg-0">
              <PageHeader title={title} />
            </div>

            {/* summary */}
            <p className="mt-32">{description}</p>

            {/* cta */}
            <a
              href={buttonUrl}
              type="button"
              className="mt-40 btn btn--red btn--round"
            >
              {buttonText}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
