import React from 'react'
import { graphql } from 'gatsby'
import map from 'lodash/map'
import DatoCMSModel from '../../models/DatoCMSModel'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'

import Hero from '@v/tools/hero'
import List from '@v/tools/list'

import './index.css'

const Page = ({ data }) => {
  const { page } = data
  const { title } = page
  const tools = map(data.tools.edges, 'node')
  const model = new DatoCMSModel(page)

  return (
    <Layout locale={page.locale} seo={{ ...model.getSeoComponentProps() }}>
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12">
            <Breadcrumb parentPages={[]} currentPage={title} />
          </div>
        </div>
      </div>

      <Hero
        title={page.heroTitle}
        description={page.heroDescription}
        buttonText={page.heroButtonText}
        buttonUrl={page.buttonUrl}
      />

      <List
        locale={page.locale}
        slug={page.slug}
        tools={tools}
        tryToolLinkText={page.tryToolLinkText}
      />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ToolsPageQuery($locale: String) {
    page: datoCmsToolsPage(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      title
      locale
      slug
      heroTitle
      heroDescription
      heroButtonText
      heroButtonUrl
      tryToolLinkText
    }

    tools: allDatoCmsTool(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id
          slug

          card {
            title
            logo {
              alt
              asset {
                localImage {
                  extension
                  publicURL
                }
              }
            }

            logoMono {
              alt
              asset {
                localImage {
                  extension
                  publicURL
                }
              }
            }
            description
          }
        }
      }
    }
  }
`
