import React from 'react'
import { Link } from 'gatsby'

import Icon from '../icon'

import './index.css'

function ToolCard({ children }) {
  const childs = React.Children.map(children, child =>
    React.cloneElement(child, { ...child.props })
  )

  return (
    <div className="tool-card border-box border-box--hover py-lg-60">
      {childs}
    </div>
  )
}

ToolCard.Logo = function ToolCardLogo({ children }) {
  return <figure className="tool-card-logo">{children}</figure>
}

ToolCard.Title = function ToolCardTitle({ children }) {
  return <h4 className="mt-24">{children}</h4>
}

ToolCard.Summary = function ToolCardSummary({ children }) {
  return <p className="mt-24">{children}</p>
}

ToolCard.Link = function ToolCardLink({ children, url }) {
  return (
    <Link className="tool-card-link d-block mt-24" to={url}>
      {children}
      <Icon className="ml-8" name="right" />
    </Link>
  )
}

export default ToolCard
